"use client";

import {
  type ISbStoryData,
  apiPlugin,
  storyblokInit,
} from "@storyblok/react/rsc";
import { useEffect, useRef, useState } from "react";

import { useEnvironment } from "~components/env-provider";

import {
  type GetSubPagesOptions,
  type SubPage,
  getDatasourceEntries,
  getSubPages,
  isStoryblokClientLoaded,
} from "./api";
import type { DataSourceEntry } from "./types";

type UseSubPagesProps = GetSubPagesOptions;

export function useSubPages<
  TStory extends { component: string } = { component: "" },
>(
  path: string,
  { ...params }: UseSubPagesProps,
  // Used mostly for testing purposes
  initialData?: SubPage<TStory>[],
) {
  const [data, setData] = useState<SubPage<TStory>[]>(initialData ?? []);

  const [isLoading, setIsLoading] = useState(true);
  const env = useEnvironment();
  const prevParams = useRef<GetSubPagesOptions>();

  useEffect(() => {
    // Make sure we only re-run this when params change.
    if (isSubPageParamsEqual(prevParams.current, params)) return;
    prevParams.current = params;

    if (env?.storyblokPreviewToken && !isStoryblokClientLoaded()) {
      // Init storyblok client on this page
      // TODO: Move to a more general place if client storyblok API usage becomes common
      storyblokInit({
        accessToken: env.storyblokPreviewToken,
        use: [apiPlugin],
      });
    }

    async function fetchSubPages() {
      setIsLoading(true);

      const fetched = await getSubPages<TStory>(path, params);

      setData(fetched);
      setIsLoading(false);
    }

    fetchSubPages();
  }, [path, params, env]);

  return {
    data,
    isLoading,
  };
}

function isSubPageParamsEqual(
  src: UseSubPagesProps | undefined,
  target: UseSubPagesProps | undefined,
) {
  return (
    src?.locale === target?.locale &&
    src?.marketId === target?.marketId &&
    src?.version === target?.version &&
    src?.resolveRelations?.join(",") === target?.resolveRelations?.join(",") &&
    src?.contentTypeFilter === target?.contentTypeFilter &&
    src?.filterQuery === target?.filterQuery &&
    src?.sortDirection === target?.sortDirection &&
    src?.page === target?.page &&
    src?.perPage === target?.perPage
  );
}

export function useGetDataSourceEntries(
  dataSourceSlug: string,
  locale: string,
  version: "draft" | "published" | undefined,
  includedIn?: string[],
  selectedIn?: string[],
) {
  const [data, setData] = useState<DataSourceEntry[]>([]);
  const env = useEnvironment();

  useEffect(() => {
    if (env?.storyblokPreviewToken && !isStoryblokClientLoaded()) {
      // Init storyblok client on this page
      // TODO: Move to a more general place if client storyblok API usage becomes common
      storyblokInit({
        accessToken: env.storyblokPreviewToken,
        use: [apiPlugin],
      });
    }

    async function loadData() {
      let fetched = (
        await getDatasourceEntries(dataSourceSlug, locale, version)
      ).map(x => ({
        ...x,
        text: x.dimension_value ?? x.value,
        isSelected: selectedIn?.includes(x.dimension_value ?? x.value) ?? false,
      }));

      fetched = fetched.filter(
        x =>
          !includedIn || includedIn.length === 0 || includedIn.includes(x.text),
      );

      setData(fetched);
    }

    loadData();
  }, [selectedIn, includedIn, dataSourceSlug, locale, version, env]);

  return {
    data,
    setData,
  };
}

import React, { type ChangeEvent, useEffect, useRef, useState } from "react";

import { Button, Icon } from "~components/ui";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~components/ui/select";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "~components/ui/sheet";
import { getLinkProps } from "~lib/storyblok";
import type { DataSourceEntry } from "~lib/storyblok/types";
import { cn } from "~utils";

import { useGetDataSourceEntries } from "~lib/storyblok/api.client";
import { RichText } from "../rich-text";
import type { EventSectionProps } from "./event-section";
import type { EventSectionBlok, EventSectionFilter } from "./types";

export const EVENT_SECTION_EMPTY_FILTER: EventSectionFilter = {
  search: "",
  sortDirection: "desc",
  eventTypeIds: [],
  continentIds: [],
  countryIds: [],
  audienceIds: [],
  themeIds: [],
};

type EventSectionFiltersProps = {
  blok: EventSectionBlok;
  currentItemsCount: number;
  totalItemsCount: number;
  filter: EventSectionFilter;
  appliedFilter: EventSectionFilter;
  setFilters: (
    setFilter: boolean,
    setApplierFilter: boolean,
    data: EventSectionFilter,
  ) => void;
} & EventSectionProps;

const dummy: EventSectionFilter = {} as EventSectionFilter;

export default function EventSectionFilters({
  blok,
  currentItemsCount,
  totalItemsCount,
  filter,
  appliedFilter,
  setFilters,
  ...context
}: EventSectionFiltersProps) {
  const searchTimerChange = useRef<number | null>(null);

  const { data: eventTypes, setData: setEventTypes } = useGetDataSourceEntries(
    "events-eventtype",
    context.locale,
    context.version,
    blok.event_type_visible_filters,
    filter.eventTypeIds,
  );
  const { data: countries, setData: setCountries } = useGetDataSourceEntries(
    "events-countries",
    context.locale,
    context.version,
    blok.country_visible_filters,
    filter.countryIds,
  );
  const { data: audiences, setData: setAudiences } = useGetDataSourceEntries(
    "events-audience",
    context.locale,
    context.version,
    blok.audience_visible_filters,
    filter.audienceIds,
  );
  const { data: themes, setData: setThemes } = useGetDataSourceEntries(
    "events-themes",
    context.locale,
    context.version,
    blok.themes_visible_filters,
    filter.themeIds,
  );

  const [countedFilters, setCountedFilters] = useState<number>(
    countAppliedFilters(),
  );

  useEffect(() => {
    setCountedFilters(countAppliedFilters());
  }, []);

  function countAppliedFilters() {
    let count = 0;

    count += appliedFilter.audienceIds?.length ?? 0;
    count += appliedFilter.continentIds?.length ?? 0;
    count += appliedFilter.countryIds?.length ?? 0;
    count += appliedFilter.eventTypeIds?.length ?? 0;
    count += appliedFilter.themeIds?.length ?? 0;

    return count;
  }

  function setSearchFilter(text: string) {
    setFilters(false, true, { ...dummy, search: text });
  }

  function clearSearchFilter() {
    if (searchTimerChange.current) {
      window?.clearTimeout(searchTimerChange.current);
      searchTimerChange.current = null;
    }

    setFilters(true, true, { ...dummy, search: "" });
  }

  function onSearchChanged(e: ChangeEvent<HTMLInputElement>) {
    setFilters(true, false, { ...dummy, search: e.target.value });

    if (searchTimerChange.current) {
      window?.clearTimeout(searchTimerChange.current);
      searchTimerChange.current = null;
    }

    searchTimerChange.current = window?.setTimeout(
      () => setSearchFilter(e.target.value),
      500,
    );
  }

  function onFilterSheetOpenChanged(e: boolean) {
    if (!e) {
      setFilters(true, false, { ...appliedFilter });
      setEventTypes(prev =>
        prev.map(x => ({
          ...x,
          isSelected: appliedFilter.eventTypeIds.includes(x.text),
        })),
      );
      setCountries(prev =>
        prev.map(x => ({
          ...x,
          isSelected: appliedFilter.countryIds.includes(x.text),
        })),
      );
      setAudiences(prev =>
        prev.map(x => ({
          ...x,
          isSelected: appliedFilter.audienceIds.includes(x.text),
        })),
      );
      setThemes(prev =>
        prev.map(x => ({
          ...x,
          isSelected: appliedFilter.themeIds.includes(x.text),
        })),
      );
    }
  }

  function onApplyFilterClicked() {
    setFilters(false, true, { ...filter });
  }

  function onClearAllFiltersClicked() {
    setFilters(true, false, { ...EVENT_SECTION_EMPTY_FILTER });
    setEventTypes(prev => prev.map(x => ({ ...x, isSelected: false })));
    setCountries(prev => prev.map(x => ({ ...x, isSelected: false })));
    setAudiences(prev => prev.map(x => ({ ...x, isSelected: false })));
    setThemes(prev => prev.map(x => ({ ...x, isSelected: false })));
  }

  function onSortDirectionValueToggle() {
    setFilters(true, true, {
      ...dummy,
      sortDirection: filter.sortDirection === "asc" ? "desc" : "asc",
    });
  }

  function onClearEventTypesFilterClicked() {
    setEventTypes(prev => prev.map(x => ({ ...x, isSelected: false })));
    setFilters(true, false, {
      ...dummy,
      eventTypeIds: [],
    });
  }

  function onEventTypeFilterClick(item: DataSourceEntry) {
    const index = filter.eventTypeIds.findIndex(x => x === item.text);

    setEventTypes(prev => {
      const newArray = [...prev];
      const eventTypeIndex = newArray.findIndex(x => x.id === item.id);
      newArray.splice(eventTypeIndex, 1, {
        ...item,
        isSelected: !(index > -1),
      });

      return newArray;
    });

    if (index > -1) {
      setFilters(true, false, {
        ...dummy,
        eventTypeIds: filter.eventTypeIds.filter(x => x !== item.text),
      });
    } else {
      setFilters(true, false, {
        ...dummy,
        eventTypeIds: [...filter.eventTypeIds, item.text],
      });
    }
  }

  function onClearCountryFilterClicked() {
    setCountries(prev => prev.map(x => ({ ...x, isSelected: false })));
    setFilters(true, false, {
      ...dummy,
      countryIds: [],
    });
  }

  function onCountrySelected(e: string) {
    setFilters(true, false, {
      ...dummy,
      countryIds: [e],
    });
  }

  function onClearAudienceFilterClicked() {
    setAudiences(prev => prev.map(x => ({ ...x, isSelected: false })));
    setFilters(true, false, {
      ...dummy,
      audienceIds: [],
    });
  }

  function onAudienceSelected(item: DataSourceEntry) {
    const index = filter.audienceIds.findIndex(x => x === item.text);

    setAudiences(prev => {
      const newArray = [...prev];
      const eventTypeIndex = newArray.findIndex(x => x.id === item.id);
      newArray.splice(eventTypeIndex, 1, {
        ...item,
        isSelected: !(index > -1),
      });

      return newArray;
    });

    if (index > -1) {
      setFilters(true, false, {
        ...dummy,
        audienceIds: filter.audienceIds.filter(x => x !== item.text),
      });
    } else {
      setFilters(true, false, {
        ...dummy,
        audienceIds: [...filter.audienceIds, item.text],
      });
    }
  }

  function onClearThemesFilterClicked() {
    setThemes(prev => prev.map(x => ({ ...x, isSelected: false })));
    setFilters(true, false, {
      ...dummy,
      themeIds: [],
    });
  }

  function onThemeSelected(item: DataSourceEntry) {
    const index = filter.themeIds.findIndex(x => x === item.text);

    setThemes(prev => {
      const newArray = [...prev];
      const eventTypeIndex = newArray.findIndex(x => x.id === item.id);
      newArray.splice(eventTypeIndex, 1, {
        ...item,
        isSelected: !(index > -1),
      });

      return newArray;
    });

    if (index > -1) {
      setFilters(true, false, {
        ...dummy,
        themeIds: filter.themeIds.filter(x => x !== item.text),
      });
    } else {
      setFilters(true, false, {
        ...dummy,
        themeIds: [...filter.themeIds, item.text],
      });
    }
  }

  return (
    <div className="z-10 flex flex-wrap gap-lg">
      <div className="flex grow justify-between gap-sm">
        {blok.leading && (
          <div className="t-prosi-xl max-w-leading text-balance text-content-heading">
            {blok.leading}
          </div>
        )}
        <div className="flex items-center rounded-md border border-solid p-2xs md:p-sm">
          <input
            value={filter.search}
            placeholder="Search"
            onChange={e => onSearchChanged(e)}
            className="t-default-sm w-[7rem] bg-[transparent] md:w-[9rem] focus:outline-none"
          />
          <Icon
            name={appliedFilter.search ? "X" : "MagnifyingGlass"}
            className="cursor-pointer"
            onClick={() => clearSearchFilter()}
            onKeyUp={() => clearSearchFilter()}
          />
        </div>
        <div className="flex items-center justify-end gap-2xs md:gap-md">
          <span className="t-default-sm">
            Showing {currentItemsCount} of {totalItemsCount} events
          </span>
          <Sheet onOpenChange={e => onFilterSheetOpenChanged(e)}>
            <SheetTrigger className="t-default-sm flex items-center rounded-lg border p-xs px-md font-bold">
              <Icon
                className="h-[1.4rem] w-[1.4rem] p-[0.25rem]"
                name="Faders"
              />
              {countedFilters === 0
                ? blok.filter_heading
                : `${countedFilters} filters applied`}
            </SheetTrigger>

            <SheetContent className="h-full w-full border border-sky-100 border-t-0 border-r-0 border-solid bg-[white] p-sm md:w-[400px] sm:w-[540px]">
              <SheetHeader>
                <div className="flex items-center justify-between pb-sm">
                  <SheetTitle>{blok.filter_heading}</SheetTitle>
                  <Button
                    className="underline"
                    onClick={() => onClearAllFiltersClicked()}
                  >
                    <Icon name="X" />
                    {blok.clear_all_text}
                  </Button>
                </div>
              </SheetHeader>
              <hr className="absolute right-[0] w-[100%] border-[#cfd6db] border-t-[1]" />
              <div className="flex items-center justify-between py-sm">
                <div className="t-default-md font-bold">
                  {blok.filter_event_type_heading}
                </div>
                <div
                  className="t-default-xs"
                  onClick={() => onClearEventTypesFilterClicked()}
                  onKeyUp={() => onClearEventTypesFilterClicked()}
                >
                  {blok.clear_text}
                </div>
              </div>
              <div className="flex flex-wrap items-start gap-2xs">
                {eventTypes?.map(item => (
                  <Button
                    key={item.id}
                    variant="primary"
                    className={
                      item.isSelected ? blok.theme : "border bg-[white]"
                    }
                    onClick={() => onEventTypeFilterClick(item)}
                  >
                    {item.text}
                  </Button>
                ))}
              </div>
              <div className="flex items-center justify-between py-sm">
                <div className="t-default-md font-bold">
                  {blok.filter_country_header}
                </div>
                <div
                  className="t-default-xs cursor-pointer"
                  onClick={() => onClearCountryFilterClicked()}
                  onKeyUp={() => onClearCountryFilterClicked()}
                >
                  {blok.clear_text}
                </div>
              </div>
              <div className="t-default-sm space-2xs flex items-center rounded-md border-2 border-solid p-sm">
                <Select
                  onValueChange={(e: string) => onCountrySelected(e)}
                  value={filter.countryIds?.[0]}
                >
                  <SelectTrigger className="w-[100%] justify-between">
                    <SelectValue placeholder="Search by countries" />
                  </SelectTrigger>
                  <SelectContent className="bg-[white] p-sm">
                    <SelectGroup>
                      {countries?.map(item => (
                        <SelectItem
                          className="cursor-pointer"
                          value={item.text}
                          key={item.id}
                        >
                          {item.text}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
              <div className="flex items-center justify-between py-sm">
                <div className="t-default-md font-bold">
                  {blok.filter_audience_heading}
                </div>
                <div
                  className="t-default-xs cursor-pointer"
                  onClick={() => onClearAudienceFilterClicked()}
                  onKeyUp={() => onClearAudienceFilterClicked()}
                >
                  {blok.clear_text}
                </div>
              </div>
              <div className="flex flex-wrap items-start gap-2xs">
                {audiences?.map(item => (
                  <Button
                    className={
                      item.isSelected ? blok.theme : "border bg-[white]"
                    }
                    key={item.id}
                    type="button"
                    onClick={() => onAudienceSelected(item)}
                  >
                    {item.text}
                  </Button>
                ))}
              </div>
              <div className="flex items-center justify-between py-sm">
                <div className="t-default-md font-bold">
                  {blok.filter_themes_header}
                </div>
                <div
                  className="t-default-xs cursor-pointer"
                  onClick={() => onClearThemesFilterClicked()}
                  onKeyUp={() => onClearThemesFilterClicked()}
                >
                  {blok.clear_text}
                </div>
              </div>
              <div className="flex flex-col flex-wrap items-start gap-2xs pb-xs">
                {themes?.map(item => (
                  <div className="flex items-center gap-xs" key={item.id}>
                    <input
                      type="checkbox"
                      className="h-md w-md rounded-md bg-satellite-100"
                      checked={item.isSelected}
                      onChange={() => onThemeSelected(item)}
                    />

                    <label> {item.text}</label>
                  </div>
                ))}
              </div>
              <hr className="absolute right-[0] w-[100%] border-[#cfd6db] border-t-[1]" />
              <SheetTrigger className={cn("mt-sm w-full", blok.theme)}>
                <Button
                  size="regular"
                  className="w-full"
                  onClick={() => onApplyFilterClicked()}
                >
                  {blok.apply_all_text}
                </Button>
              </SheetTrigger>
            </SheetContent>
          </Sheet>
          <Button
            onClick={() => onSortDirectionValueToggle()}
            variant="secondary"
          >
            <Icon name="ArrowsDownUp" />
            {appliedFilter.sortDirection === "asc"
              ? "Oldest event first"
              : "Newest event first"}
          </Button>
        </div>
        <RichText
          className="t-strong-lg max-w-sub text-pretty"
          data={blok.text}
          {...context}
        />

        {blok.ctaLink && blok.ctaText && (
          <Button
            {...getLinkProps(blok.ctaLink, context)}
            arrow
            variant="secondary"
            className="mr-auto"
          >
            {blok.ctaText}
          </Button>
        )}
      </div>
    </div>
  );
}

import type { EventSectionFilter } from "./types";

export function loadQueryParams(
  defaultState: EventSectionFilter,
): EventSectionFilter {
  if (typeof window === "undefined") {
    return defaultState;
  }

  const urlParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlParams.entries());

  const parseArrayParams = (key: string): string[] => {
    const value = params[key];
    return value ? value.split(",") : [];
  };

  return {
    ...defaultState,
    search: params.search ?? "",
    eventTypeIds: parseArrayParams("eventTypeIds"),
    continentIds: parseArrayParams("continentIds"),
    countryIds: parseArrayParams("countryIds"),
    audienceIds: parseArrayParams("audienceIds"),
    themeIds: parseArrayParams("themeIds"),
  };
}

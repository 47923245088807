"use client";

import { storyblokEditable } from "@storyblok/react/rsc";
import { useEffect, useState } from "react";

import { Button, Icon, Rails } from "~components/ui";
import { SeedWave } from "~components/ui/seed-wave";
import type { PageContext, SubPage } from "~lib/storyblok";
import { useSubPages } from "~lib/storyblok/api.client";
import { cn } from "~utils";

import type { EventPageBlok } from "../event-page";
import { getCategoryFilters } from "../news-page/helpers";
import EventSectionFilters from "./event-section-filters";
import { EventSectionItem } from "./event-section-item";
import { loadQueryParams } from "./functions";
import type {
  EventPageItemType,
  EventSectionBlok,
  EventSectionFilter,
} from "./types";

export const EVENT_SECTION_EMPTY_FILTER: EventSectionFilter = {
  search: "",
  sortDirection: "desc",
  eventTypeIds: [],
  continentIds: [],
  countryIds: [],
  audienceIds: [],
  themeIds: [],
};

const EVENT_SECTION_FILTER_INITIAL: EventSectionFilter = loadQueryParams(
  EVENT_SECTION_EMPTY_FILTER,
);

export type EventSectionProps = {
  blok: EventSectionBlok;
  // initialData?: SubPage<EventPageItemType>[];
} & PageContext;

export function EventSection({
  // initialData,
  blok,
  ...context
}: EventSectionProps) {
  const pageSize = blok.pageSize ?? 6;
  const [filter, setFilter] = useState<EventSectionFilter>({
    ...EVENT_SECTION_FILTER_INITIAL,
  });
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [subPages, setSubPages] = useState<SubPage<EventPageBlok>[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [initialized, setInitialized] = useState<boolean>(false);

  const [appliedFilter, setAppliedFilter] = useState<EventSectionFilter>({
    ...EVENT_SECTION_FILTER_INITIAL,
  });

  const [params, setParams] = useState({
    ...context,
    ...getCategoryFilters("event"),
    page: pageNumber,
    perPage: pageSize,
    sortDirection: appliedFilter?.sortDirection,
    filterQuery: parseParamsForStoryblok(),
  });

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      return;
    }

    setParams(prev => ({
      ...prev,
      sortDirection: appliedFilter?.sortDirection,
      page: pageNumber,
      filterQuery: parseParamsForStoryblok(),
    }));
    saveFilterToQueryString();
  }, [appliedFilter, pageNumber, initialized]);

  function parseParamsForStoryblok() {
    return {
      title: {
        like: parseSearch(appliedFilter.search),
      },
      ...(appliedFilter.eventTypeIds.length > 0
        ? {
            type: {
              any_in_array: appliedFilter.eventTypeIds?.join(","),
            },
          }
        : {}),
      ...(appliedFilter.audienceIds?.length > 0
        ? {
            audience: {
              in: appliedFilter.audienceIds.join(","),
            },
          }
        : {}),
      ...(appliedFilter.themeIds?.length > 0
        ? {
            theme: {
              any_in_array: appliedFilter.themeIds.join(","),
            },
          }
        : {}),
      ...(appliedFilter.countryIds?.length > 0
        ? {
            country: {
              in: appliedFilter.countryIds.join(","),
            },
          }
        : {}),
    };
  }

  function saveFilterToQueryString() {
    const filterObj: { [key: string]: string | string[] } = {
      ...appliedFilter,
    };

    for (const field of Object.keys(filterObj)) {
      if (
        !filterObj[field] ||
        (Array.isArray(filterObj[field]) && filterObj[field]?.length === 0)
      ) {
        delete filterObj[field];
      }
    }

    const processedFilterObj: Record<string, string> = Object.fromEntries(
      Object.entries(filterObj).map(([key, value]) => [
        key,
        Array.isArray(value) ? value.join(",") : value,
      ]),
    );

    const urlParams = new URLSearchParams(processedFilterObj);
    window?.history.pushState(
      null,
      "",
      `${window?.location.pathname}?${urlParams}`,
    );
  }

  function onViewMore() {
    setPageNumber(prev => prev + 1);
  }

  const { data, isLoading } = useSubPages<EventPageItemType>(
    "wip/events",
    params,
  );

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      return;
    }

    setSubPages(data?.slice(0, pageNumber * pageSize) ?? []);
    setTotal(data?.length);
  }, [data, pageNumber, pageSize, initialized]);

  function parseSearch(searchText: string) {
    if (!searchText) {
      return "*";
    }
    return `%${searchText}%`;
  }

  let subPagesContent = <p>No items found</p>;

  if (subPages?.length > 0) {
    subPagesContent = (
      <>
        {subPages.map(item => (
          <EventSectionItem
            key={item.story.id}
            story={item.story}
            url={item.url}
            {...context}
          />
        ))}
      </>
    );
  }

  function setFilters(
    shuoldSetFilter: boolean,
    shouldSetAppliedFilter: boolean,
    data: EventSectionFilter,
  ) {
    if (shuoldSetFilter) {
      setFilter(prev => ({
        ...prev,
        ...data,
      }));
    }

    if (shouldSetAppliedFilter) {
      setAppliedFilter(prev => ({
        ...prev,
        ...data,
      }));
    }
  }

  return (
    <Rails.Root
      id={blok.sectionId}
      data-section-type="tile"
      className={cn(
        "section relative overflow-hidden",
        blok.theme,
        blok.backgroundVariant === "contrast" && "bg-surface/contrast",
        blok.backgroundVariant === "default" && "bg-surface/100",
        blok.backgroundVariant === "darker" && "bg-surface/200",
      )}
      {...storyblokEditable(blok)}
    >
      <EventSectionFilters
        blok={blok}
        totalItemsCount={total}
        currentItemsCount={subPages.length}
        filter={filter}
        appliedFilter={appliedFilter}
        setFilters={setFilters}
        {...context}
      />

      <div className="grid grid-cols-1 place-items-start gap-x-sm gap-y-xl md:grid-cols-[repeat(auto-fill,minmax(15em,1fr))]">
        {subPagesContent}
      </div>

      {subPages?.length < total && (
        <div className="flex w-full">
          <Button
            variant="ternary"
            className={"mx-auto mt-auto"}
            size="small"
            onClick={() => onViewMore()}
          >
            <div className="flex items-center justify-between gap-2xs">
              View more
              <Icon name="ArrowDown" weight="bold" />
            </div>
          </Button>
        </div>
      )}

      {blok.withSeedsPattern && (
        <SeedWave
          className="section-item-full absolute top-[0] z-0 h-full w-full bg-decor/200"
          seedPatternColor={blok.seedPatternColor}
        />
      )}
    </Rails.Root>
  );
}

import { type SbBlokData, storyblokEditable } from "@storyblok/react/rsc";
import Image from "next/image";

import { Button, Icon } from "~components/ui";
import {
  type PageContext,
  buildImageLoader,
  getLinkProps,
} from "~lib/storyblok";
import { cn } from "~utils";

import { RichText } from "../rich-text";
import { FeatureSectionVideo } from "./feature-section-video";
import type { FeaturesSectionBlok } from "./types";

type SplitSectionProps = {
  blok: FeaturesSectionBlok;
} & PageContext;

export function FeaturesSection({ blok, ...context }: SplitSectionProps) {
  return (
    <section
      id={blok.sectionId}
      data-section-type="features"
      className={cn(
        blok.theme,
        "section bg-surface/200",
        blok.backgroundVariant === "darker" && "bg-surface/300",
        blok.backgroundVariant === "lighter" && "bg-surface/100",
        blok.backgroundVariant === "white" && "bg-others-white",
      )}
      {...storyblokEditable(blok)}
    >
      <div className={cn("grid gap-2xl", blok.variant === "cards" && "gap-lg")}>
        <div className={cn("grid gap-lg", blok.variant === "cards" && "mb-lg")}>
          <div className="flex flex-col gap-sm">
            {blok.leading && (
              <span className="t-prosi-xl -mb-2xs max-w-sub text-content-heading">
                {blok.leading}
              </span>
            )}
            {blok.title && (
              <h2 className="t-strong-3xl max-w-title text-balance text-content-heading">
                {blok.title}
              </h2>
            )}
            <RichText
              data={blok.text}
              className="t-strong-lg max-w-sub text-pretty"
              {...context}
            />

            {blok.ctaLink && (
              <Button
                {...getLinkProps(blok.ctaLink, context)}
                variant={blok.variant === "simple" ? "primary" : "secondary"}
                editorial
                className="mt-sm place-self-start"
              >
                {blok.ctaText}
              </Button>
            )}
          </div>

          {blok.items.length ? (
            <ul
              className={cn(
                "grid",
                blok.variant === "simple"
                  ? "md:grid-cols-[repeat(auto-fit,17.5rem)]"
                  : "xl:grid-cols-3",
              )}
            >
              {blok.items.map(item => (
                <li
                  key={item._uid}
                  className={cn(
                    "flex gap-sm p-sm",
                    blok.variant === "simple"
                      ? "t-strong-lg md:flex-col max-md:items-center"
                      : "flex max-xl:items-center",
                  )}
                  {...storyblokEditable(item as SbBlokData)}
                >
                  <Icon
                    name={item.icon}
                    weight="duotone"
                    className="circle-icon"
                  />
                  <RichText
                    data={item.text}
                    {...context}
                    className="max-w-sub text-pretty"
                  />
                </li>
              ))}
            </ul>
          ) : null}
        </div>
        <>
          {blok.variant !== "simple"
            ? blok.linkItems.map(item => (
                <div
                  key={item._uid}
                  className={cn(
                    "flex place-items-center gap-lg max-xl:flex-col md:gap-xl-2xl",
                    blok.variant === "cards" &&
                      "flex-row-reverse rounded-sm p-lg md:py-xl",
                    blok.variant === "cards" &&
                      item.backgroundVariant === "lighter" &&
                      "bg-surface/100",
                    blok.variant === "cards" &&
                      item.backgroundVariant === "default" &&
                      "bg-surface/200",
                    blok.variant === "cards" &&
                      item.backgroundVariant === "darker" &&
                      "bg-surface/300",
                    blok.variant === "cards" &&
                      item.backgroundVariant === "accent" &&
                      "bg-surface/accent",
                  )}
                  {...storyblokEditable(item)}
                >
                  {item.image?.filename && (
                    <Image
                      sizes="(min-width: 1280px) 440px, 100vw"
                      src={item.image.filename}
                      width={440}
                      height={440}
                      loader={buildImageLoader({
                        aspectRatio: 1,
                        focus: item.image.focus,
                      })}
                      alt={item.image.alt}
                      className="w-full"
                    />
                  )}

                  {item.video?.filename && (
                    <FeatureSectionVideo item={item} {...context} />
                  )}

                  <div className="flex flex-col place-items-start gap-sm">
                    {item.title && (
                      <h4 className="t-strong-2xl max-w-title text-balance text-content-heading">
                        {item.title}
                      </h4>
                    )}

                    <RichText
                      data={item.text}
                      className="t-strong-lg max-w-text text-pretty"
                      {...context}
                    />
                    {item.ctaLink &&
                      item.ctaText &&
                      (blok.variant === "cards" ? (
                        <Button
                          {...getLinkProps(item.ctaLink, context)}
                          className="-ml-md"
                          variant="ternary"
                        >
                          {item.ctaText}
                        </Button>
                      ) : (
                        <Button
                          editorial
                          {...getLinkProps(item.ctaLink, context)}
                        >
                          {item.ctaText}
                        </Button>
                      ))}
                  </div>
                </div>
              ))
            : null}
        </>
      </div>
    </section>
  );
}

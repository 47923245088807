import type { StoryblokLink } from "~lib/storyblok";
import type { IconName } from "./ui";

export type TileThemes =
  | "tile-satellite"
  | "tile-sky"
  | "tile-sand"
  | "tile-soil";
export type SectionThemes =
  | "section-satellite"
  | "section-sky"
  | "section-sand";
export type Themes = TileThemes & SectionThemes;

const articleTypeList = ["blog", "news", "insight", "report"] as const;

export const articleTypes = articleTypeList.map(String);
export type ArticleType = (typeof articleTypeList)[number];

export type Link = {
  _uid: string;
  component: string;
  label: string;
  url: StoryblokLink;
  iconPhosphor?: IconName;
};

"use client";

import * as SelectPrimitive from "@radix-ui/react-select";
import type { SelectProps } from "@radix-ui/react-select";
import * as React from "react";

import { Icon } from "~components/ui";
import { cn } from "~utils";

function Select({ children, ...props }: SelectProps) {
  return <SelectPrimitive.Root>{children}</SelectPrimitive.Root>;
}

function SelectGroup({ children }: SelectPrimitive.SelectGroupProps) {
  return <SelectPrimitive.Group>{children} </SelectPrimitive.Group>;
}

function SelectValue({ children, ...props }: SelectPrimitive.SelectValueProps) {
  return <SelectPrimitive.Value {...props} />;
}

function SelectTrigger({
  children,
  className,
  ...props
}: SelectPrimitive.SelectTriggerProps) {
  return (
    <SelectPrimitive.Trigger
      className={cn("flex items-center gap-3xs focus:outline-none", className)}
      {...props}
    >
      {children}
      <SelectPrimitive.Icon asChild>
        <Icon
          name="CaretDown"
          style={{ backgroundColor: "#d9d9d9", padding: "4px" }}
          className="h-[1.4rem] w-[1.4rem] rounded-lg"
        />
      </SelectPrimitive.Icon>
    </SelectPrimitive.Trigger>
  );
}

function SelectContent({
  children,
  className,
  position = "popper",
  ...props
}: SelectPrimitive.SelectContentProps) {
  return (
    <SelectPrimitive.Portal>
      <SelectPrimitive.Content
        className={cn(
          "relative z-50 min-w-[8rem] overflow-hidden rounded-md border shadow-md",
          className,
        )}
        position={position}
        {...props}
      >
        <SelectPrimitive.Viewport
          className={cn(
            "p-1",
            position === "popper" &&
              "h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]",
          )}
        >
          {children}
        </SelectPrimitive.Viewport>
      </SelectPrimitive.Content>
    </SelectPrimitive.Portal>
  );
}

function SelectLabel({
  className,
  ...props
}: SelectPrimitive.SelectLabelProps) {
  return (
    <SelectPrimitive.Label className={cn("font-bold", className)} {...props} />
  );
}

function SelectSeparator({
  className,
  ...props
}: SelectPrimitive.SelectSeparatorProps) {
  return (
    <SelectPrimitive.Separator
      className={cn("-mx-1 my-1 h-px bg-muted", className)}
      {...props}
    />
  );
}

function SelectItem({
  className,
  children,
  ...props
}: SelectPrimitive.SelectItemProps) {
  return (
    <SelectPrimitive.Item
      className={cn("py-2xs focus:outline-none", className)}
      {...props}
    >
      <span className="absolute right-[2rem] flex items-center justify-center">
        <SelectPrimitive.ItemIndicator>
          <Icon name="Check" />
        </SelectPrimitive.ItemIndicator>
      </span>
      <SelectPrimitive.ItemText key={12}>{children}</SelectPrimitive.ItemText>
    </SelectPrimitive.Item>
  );
}

export {
  Select,
  SelectValue,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectLabel,
  SelectSeparator,
  SelectGroup,
};

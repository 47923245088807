import {
  type ISbRichtext,
  StoryblokComponent,
  storyblokEditable,
} from "@storyblok/react/rsc";
import { useMemo } from "react";
import {
  MARK_LINK,
  NODE_HEADING,
  NODE_HR,
  NODE_LI,
  NODE_OL,
  NODE_PARAGRAPH,
  NODE_QUOTE,
  NODE_UL,
} from "storyblok-rich-text-react-renderer";

import {
  getLinkMarkProps,
  getReadingTime,
  isWrapperNode,
} from "~lib/storyblok";
import type { PageContext } from "~lib/storyblok/page";
import { formatDate, formatMinutes } from "~utils";

import { type PersonGalleryBlok, RichText, type TableBlok } from "../rich-text";
import { PersonGallery } from "../rich-text/person-gallery";
import { Table } from "../rich-text/table";
import type { EventPageBlok } from "./types";

type ArticlePageProps = {
  blok: EventPageBlok;
} & PageContext;

export function EventPage({ blok, ...context }: ArticlePageProps) {
  const readingTime = useMemo(() => getReadingTime(blok.text), [blok.text]);

  if (blok.isDisabled) {
    return <code className="flex p-lg">Article disabled for this market</code>;
  }

  return (
    <main {...storyblokEditable(blok)} data-page-type="event">
      {blok.body?.map(nestedBlok => (
        <StoryblokComponent
          blok={nestedBlok}
          key={nestedBlok._uid}
          {...context}
        />
      ))}
    </main>
  );
}

type EventPageBodyProps = {
  text: ISbRichtext;
} & PageContext;

function EventPageBody({ text, ...context }: EventPageBodyProps) {
  return (
    <div>
      <RichText
        data={text}
        {...context}
        markResolvers={{
          [MARK_LINK]: (children, props) => (
            <a {...getLinkMarkProps(props, context)}>{children}</a>
          ),
        }}
        blokResolvers={{
          PersonGallery: props => (
            <PersonGallery
              blok={props as PersonGalleryBlok}
              {...context}
              className="my-md mb-lg"
            />
          ),
          Table: props => (
            <Table blok={props as TableBlok} className="my-sm mb-md" />
          ),
        }}
        nodeResolvers={{
          [NODE_PARAGRAPH]: children => {
            // Unwrap elements when there is no need for extra paragraph
            if (isWrapperNode(children)) return <>{children}</>;

            return (
              <p className="mb-sm text-pretty [&_a]:text-satellite-accent [&_a]:underline">
                {children}
              </p>
            );
          },
          [NODE_HEADING]: (children, { level }) => {
            switch (level) {
              case 3:
                return (
                  <h3 className="t-strong-lg mt-lg mb-sm max-w-[45ch] text-balance text-content-heading leading-tight">
                    {children}
                  </h3>
                );
              case 4:
                return (
                  <h4 className="t-strong-lg mt-lg mb-sm max-w-[45ch] text-balance text-content-heading leading-tight">
                    {children}
                  </h4>
                );
              case 5:
                return (
                  <h5 className="t-strong-lg mt-lg mb-sm max-w-[45ch] text-balance text-content-heading leading-tight">
                    {children}
                  </h5>
                );
              default:
                return (
                  <h2 className="t-strong-lg mt-lg mb-sm max-w-[45ch] text-balance text-content-heading leading-tight">
                    {children}
                  </h2>
                );
            }
          },
          [NODE_QUOTE]: children => (
            <blockquote className="t-prosi-md my-lg text-balance border-content-link border-l-2 pl-lg">
              {children}
            </blockquote>
          ),
          [NODE_UL]: children => (
            <ul className="my-base grid list-disc gap-2xs pl-sm">{children}</ul>
          ),
          [NODE_OL]: children => (
            <ol className="my-base grid list-disc gap-2xs pl-sm">{children}</ol>
          ),
          [NODE_LI]: children => (
            <li className="list-item [&_p]:mb-[0px]">{children}</li>
          ),
          [NODE_HR]: () => <hr className="my-sm" />,
        }}
      />
    </div>
  );
}

import type { ComponentProps } from "react";
import { VideoPlayer } from "~components/ui";
import { type PageContext, getPosterUrl } from "~lib/storyblok";
import { cn, useScreenSize } from "~utils";
import type { VideoSectionBackgroundBlok } from "./types";

type VideoSectionBackground = {
  blok: VideoSectionBackgroundBlok;
} & PageContext;

const className: Pick<
  ComponentProps<typeof VideoPlayer>,
  "className" | "classNameVideo"
> = {
  className: "rounded-none",
  classNameVideo: "rounded-none",
};

function VideoSectionBackground({ blok, ...context }: VideoSectionBackground) {
  const { isMobile } = useScreenSize();

  return (
    <section className="z-10 w-full select-none overflow-hidden">
      {!blok.youtubeId && blok.video?.filename && (
        <VideoPlayer
          src={blok.video.filename}
          captions={blok.captions}
          poster={
            blok.poster
              ? getPosterUrl({
                  image: blok.poster,
                  width: isMobile ? 600 : 1280,
                })
              : undefined
          }
          disablePictureInPicture={blok.disablePictureInPicture}
          controls={blok.controls}
          isAutoplay={blok.autoplay}
          {...context}
          {...className}
        />
      )}

      {blok.youtubeId && (
        <iframe
          src={`https://www.youtube.com/embed/${blok.youtubeId}`}
          title={blok.youtubeTitle}
          width={isMobile ? 600 : 1280}
          className={cn("w-full", isMobile ? "aspect-[4/3]" : "aspect-video")}
        />
      )}
    </section>
  );
}

export { VideoSectionBackground };

import { type ISbStoryData, storyblokEditable } from "@storyblok/react";
import Image from "next/image";

import { Button, Icon } from "~components/ui";
import {
  type PageContext,
  buildImageLoader,
  getLinkProps,
} from "~lib/storyblok";
import { cn, getMonthShort, getWeekdayShort } from "~utils";

import { RichText } from "../rich-text";

type TileItemProps = {
  url: string;
  story: ISbStoryData;
} & PageContext;

export function EventSectionItem({
  story: item,
  url,
  ...context
}: TileItemProps) {
  // #region Date box texts
  let daysOfWeekText = "";
  let datesText = "";
  let monthsText = "";

  const fromDate = item.content.date ? new Date(item.content.date) : null;
  fromDate?.setHours(0, 0, 0, 0);

  if (fromDate) {
    daysOfWeekText = getWeekdayShort(fromDate);
    datesText = fromDate.getDate().toString().padStart(2, "0");
    monthsText = getMonthShort(fromDate);
  }

  const endDate = item.content.endDate ? new Date(item.content.endDate) : null;
  endDate?.setHours(0, 0, 0, 0);

  if (endDate && endDate.toDateString() !== fromDate?.toDateString()) {
    daysOfWeekText += ` -
      ${getWeekdayShort(endDate)}`;
    datesText += ` - ${endDate.getDate().toString().padStart(2, "0")}`;

    if (endDate.getMonth() !== fromDate?.getMonth()) {
      monthsText += ` - ${getMonthShort(endDate)}`;
    }
  }
  // #endregion

  const externalUrl = item.content.externalUrl;

  return (
    <a
      href={externalUrl || url}
      target={externalUrl ? "_blank" : "_self"}
      {...storyblokEditable(item.content)}
      className={cn(
        "flex h-full w-full shrink-0 snap-center flex-col border border-border/300 border-solid",
        "overflow-hidden rounded-md md:min-h-[23em]",
      )}
    >
      {item.content.image?.filename && (
        <div className="relative">
          <Image
            width="307"
            height="180"
            alt={item.content.image?.alt ?? ""}
            src={item.content.image.filename}
            loader={buildImageLoader({
              aspectRatio: 307 / 180,
              focus: item.content.image.focus,
            })}
            className="w-full md:hidden"
          />
          <Image
            width={460}
            height="240"
            sizes="(max-width: 1048px) 80vw, 40vw"
            alt={item.content.image?.alt ?? ""}
            src={item.content.image.filename}
            loader={buildImageLoader({
              aspectRatio: 460 / 240,
              focus: item.content.image.focus,
            })}
            className="w-full max-md:hidden"
          />
          {fromDate && (
            <div className="absolute top-[0] right-[0] m-xs rounded-md bg-[#E3EAF0] p-2xs text-center">
              <p className="t-default-xs">{daysOfWeekText}</p>
              <p className="t-default-lg font-bold">{datesText}</p>
              <p className="t-default-xs">{monthsText}</p>
            </div>
          )}
        </div>
      )}

      <div className="flex flex-1 flex-col place-content-start gap-sm p-md text-content-text md:p-lg">
        <h2 className="t-default-lg font-bold text-satellite-accent">
          {item.content.title}
        </h2>

        {item.content.leading && (
          <span className="t-default-lg">{item.content.leading}</span>
        )}

        <RichText
          className="t-default-md text-pretty"
          data={item.content.text}
          {...context}
        />

        {/* {item.content.ctaLink && item.content.ctaText && ( */}
        <Button
          className={"mt-auto"}
          size="small"
          {...getLinkProps(
            externalUrl
              ? { linktype: "url", url: externalUrl, target: "_blank" }
              : item.content.ctaLink,
            context,
          )}
          style={{ maxWidth: "180px", width: "100%" }}
        >
          <div className="flex items-center justify-between gap-2xs">
            {item.content.registerNowText}
            <Icon name="ArrowRight" weight="bold" />
          </div>
        </Button>
        {/* )} */}
      </div>
    </a>
  );
}
